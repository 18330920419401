/*
  palette
  61b15a - bright green
  adce74 - light bright green
  fff76a - bright yellow
  ffce89 - brown yellow
*/

.App {
  text-align: center;
  background-color: #fff7eb;
  min-height: 100vh;
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

h1,h2,h4 {
  color: #5c6649;
}

.main {
  min-height: 100vh;
}

.panel {
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
	border-radius: 5px;
	background: white;
	padding: 2%;
	width: 90%;
	margin: auto;
  margin-top: auto;
}

.search_menu {
  margin:2px;
  border-radius: 5px;
  background: #e3e3e3;
  padding:2px;
}

.search-image {
  max-width : 200px;
  max-height : 100px;
  width : auto;
  height : auto;

}

.show-select-img {
  margin: 1rem;
  width: 40vh;
  height: 30vh;
}

.image-lg {
  width: 50vh;
  height: 50vh;
}

.add-space {
  margin: 4px;
}

.rating-container {
  display: flex;
}

.rating-container > div {
  padding: 1px;
}

.left-align-cols > div {
  text-align: left;
}

.navbar-custom {
  background-color:#61b15a;
}

.navlinks-custom > a:: {

}

.navbar-light .navbar-nav .nav-link {
    color: #fff !important;
}

.navbar-light .navbar-nav .nav-link:hover {
    color: #fff76a !important;
}

.btn-outline-success {
    color:  #fff76a !important;
    border-color: #fff76a !important;
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28 255, 248, 106, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;
}

.navbar-light .navbar-toggler {
    color: rgba(255, 248, 106,.5) !important;
    border-color: rgba(255, 248, 106,.1) !important;
}

.navbar-toggler {
    padding: .25rem .75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: .25rem;
}


.error {
  color: #b34436;
}

/**************************HERO IMAGE SECTION******************************/

header {
  background-image: -webkit-gradient(linear,left top, left bottom,from(rgba(255, 255, 255, 0.9)),to(rgba(255, 255, 255, 0.9))),url("https://tvsmashup.chrisbriant.uk/smashupmontage.png");
  background-image: linear-gradient(rgba(255, 255, 255, 0.9),rgba(255, 255, 255, 0.9)),url("https://tvsmashup.chrisbriant.uk/smashupmontage.png");
  min-height :88vh;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}

header > h1 {
  opacity: 1;
}


/**********************vs image********************************************/
.vsimg-small {
  width: 6rem;
  height: 4rem;
}

/**********************footer********************************************/
footer {
  min-height:10vh;
  background-color: #ffce89;
}

/***********************modals******************************************/

.modal-80w {
  min-width:80%;
}

.cookie-content {
  max-height: 60vh;
  scroll-behavior: auto;
  overflow:scroll;
}
